import { HttpErrorResponse } from '@angular/common/http';

export const getApiErrors = function(response: HttpErrorResponse) {
  if (response && response.error && response.error.errors) {
    const errorKeys = Object.keys(response.error.errors);
    return errorKeys.map((key) => {
      return response.error.errors[key];
    });
  } else {
    if (response.error && response.error.message) {
      return [response.error.message];
    }
    return [response.message];
  }
};
