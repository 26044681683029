import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ProblemModel } from '../../core/models/problem.model';
import { ProblemImageModel } from '../../core/models/problem-image.model';
import { ProblemForm } from '../../core/models/problem.form';
import { UserSubmissionModel } from 'src/app/core/models/newmodels/user.submission.model';

@Injectable({
  providedIn: 'root',
})
export class ProblemService {
  constructor(private http: HttpClient) {}

  getProblemsByQuery(enpointUrl: string) {
    return this.http.get(`${environment.urls.api}/api/${enpointUrl}`);
  }

  getCategories() {
    return this.http.get(`${environment.urls.api}/api/problems/categories`);
  }

  show(problemId: string | number) {
    return this.http.get(`${environment.urls.api}/api/problems/${problemId}`);
  }

  complete(model: ProblemModel) {
    return this.http.patch(
      `${environment.urls.api}/api/problems/${model.id}/complete`,
      { complete: true }
    );
  }

  reject(model: ProblemModel) {
    return this.http.patch(
      `${environment.urls.api}/api/problems/${model.id}/defer`,
      { defer: true, comment: model.comment }
    );
  }

  create(model: ProblemForm) {
    const input = new FormData();
    if (model && model.images && model.images.length > 0) {
      (model.images as Blob[]).forEach((image: Blob) => {
        input.append('image[]', image);
      });
    }

    input.append('title', model.title);
    input.append('description', model.description);
    input.append('report_to_id', model.report_to_id as string);
    input.append('latitude', model.latitude as string);
    input.append('longitude', model.longitude as string);

    const httpOptions = {
      headers: null,
    };

    return this.http.post(
      `${environment.urls.api}/api/problems`,
      input,
      httpOptions
    );
  }

  createSubmission(model: UserSubmissionModel) {
    return this.http.post(
      `${environment.urls.api}/api/PwaSubmission/CreateAppClientSubmission`,
      model
    );
  }

  // SUBMISSIOSN

  // type (task:0, problem:1)
  // status (...)
  getClientSubmissionsByAssignedByUserId(
    userId: string,
    type: number = 0,
    status: number = 0
  ) {
    return this.http.get(
      `${environment.urls.api}/api/PwaSubmission/getClientSubmissionsByAssignedByUserId?userId=${userId}&type=${type}&status=${status}`
    );
  }
  getClientSubmissionsByAssignedToUserId(
    userId: string,
    type: number = 0,
    status: number = 0
  ) {
    return this.http.get(
      `${environment.urls.api}/api/PwaSubmission/getClientSubmissionsByAssignedToUserId?userId=${userId}&type=${type}&status=${status}`
    );
  }

  getClientSubmissionsById(id: string, userId: string) {
    return this.http.get(
      `${environment.urls.api}/api/PwaSubmission/GetClientSubmissionsById?id=${id}&userId=${userId}`
    );
  }

  rejectSubmission(
    submissionId: string,
    userId: string,
    rejectionReason: string
  ) {
    return this.http.get(
      `${environment.urls.api}/api/PwaSubmission/rejectSubmission?submissionId=${submissionId}&userId=${userId}&rejectionReason=${rejectionReason}`
    );
  }
  completeSubmission(
    submissionId: string,
    userId: string,
    completeReason: string
  ) {
    return this.http.get(
      `${environment.urls.api}/api/PwaSubmission/completeSubmission?submissionId=${submissionId}&userId=${userId}&completedReason=${completeReason}`
    );
  }

  acknowledgeSubmissionForCompletion(submissionId: string, userId: string) {
    return this.http.get(
      `${environment.urls.api}/api/PwaSubmission/acknowledgeSubmissionForCompletion?submissionId=${submissionId}&userId=${userId}`
    );
  }
}
